<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {

  },
};
</script>

<style>
</style>
